<template>
    <div v-if="itemForm.type === 'questionnaire' || itemForm.type === 'question' || itemForm.type === 'end'">
        <div v-if="!showAnswers" key="question">
            <b-form-group label="Titre :" label-for="name-question-input" invalid-feedback="Un titre est requis" :state="error.title">
                <b-form-input
                    :state="error.title"
                    id="name-question-input"
                    placeholder="Entrez ici le titre de votre question"
                    v-model="itemForm.title"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Message affiché en cas d'échec : "
                label-for="message-question-input"
                invalid-feedback="Le message à afficher en cas d'échec est requis"
                :state="error.errorMessage"
            >
                <b-form-input
                    :state="error.errorMessage"
                    id="message-question-input"
                    placeholder="Entrez ici votre message"
                    v-model="itemForm.message"
                    required
                ></b-form-input>
            </b-form-group>
            <div v-if="(itemForm.type === 'questionnaire') | (itemForm.type === 'end')">
                <div style="width : 70%" class="d-flex justify-content-between align-items-center flex-wrap">
                    <b-form-group
                        label="Score : "
                        label-for="goal-question-input"
                        invalid-feedback="Veuillez sélectionner un score minimum"
                        style="width : 85%"
                    >
                        <b-form-input
                            type="range"
                            v-model.number="itemForm.goal"
                            min="0"
                            max="100"
                            step="5"
                            class=" mx-5 form-control-range"
                            id="goal-question-input"
                            required
                        />
                    </b-form-group>
                    <span class="text-muted">{{ itemForm.goal }}</span>
                </div>
                <b-form-checkbox id="checkbox-1" v-model="itemForm.type" name="checkbox-1" value="end" unchecked-value="questionnaire">
                    Définir en tant qu'examen final !
                </b-form-checkbox>
            </div>
        </div>
        <div v-if="showAnswers">
            <div key="question">
                <b-form-group
                    :state="error.question"
                    :label="'Question ' + (parseInt(statesItemForm.onFocus) + 1)"
                    label-for="question-input"
                    invalid-feedback="Une question est requise"
                >
                    <b-form-input
                        :state="error.question"
                        id="question-input"
                        placeholder="Entrez ici votre question"
                        v-model="inputQuestionItem.question"
                        required
                    ></b-form-input>
                </b-form-group>
            </div>
            <div key="awnsers">
                <b-form-group>
                    <b-form-checkbox-group id="checkbox-group-2" v-model="inputQuestionItem.goodAnswers">
                        <b-form-group label="Réponse A :" invalid-feedback="La réponse A est requise" label-for="answerA-input">
                            <section style="align-items: stretch" class="d-flex fex-row justify-content-around">
                                <div class="d-flex align-items-center justify-content-center" style="width:12%">
                                    <b-form-checkbox value="A" size="lg" />
                                </div>
                                <div style="width:85%">
                                    <b-form-input
                                        v-model="inputQuestionItem.answerA"
                                        id="answerA-input"
                                        :state="error.answerACheckBox"
                                        placeholder="Entrez ici la réponse A"
                                        invalid-feedback="La réponse A est requise"
                                        required
                                    />
                                </div>
                            </section>
                        </b-form-group>
                        <b-form-group label="Réponse B :" invalid-feedback="La réponse B est requise">
                            <section style="align-items: stretch" class="d-flex fex-row justify-content-around">
                                <div class="d-flex align-items-center justify-content-center" style="width:12%">
                                    <b-form-checkbox value="B" size="lg" :state="error.answerBCheckBox" />
                                </div>
                                <div style="width:85%">
                                    <b-form-input
                                        v-model="inputQuestionItem.answerB"
                                        id="answerB-input"
                                        :state="error.answerBCheckBox"
                                        placeholder="Entrez ici la réponse B"
                                        required
                                    />
                                </div>
                            </section>
                        </b-form-group>
                        <b-form-group label="Réponse C :">
                            <section style="align-items: stretch" class="d-flex fex-row justify-content-around">
                                <div class="d-flex align-items-center justify-content-center" style="width:12%">
                                    <b-form-checkbox value="C" size="lg" />
                                </div>
                                <div style="width:85%">
                                    <b-form-input
                                        v-model="inputQuestionItem.answerC"
                                        id="answerC-input"
                                        :state="error.answerCCheckBox"
                                        placeholder="Entrez ici la réponse C"
                                    />
                                </div>
                            </section>
                        </b-form-group>
                        <b-form-group label="Réponse D :">
                            <section style="align-items: stretch" class="d-flex fex-row justify-content-around">
                                <div class="d-flex align-items-center justify-content-center" style="width:12%">
                                    <b-form-checkbox value="D" size="lg" />
                                </div>
                                <div style="width:85%">
                                    <b-form-input
                                        v-model="inputQuestionItem.answerD"
                                        id="answerD-input"
                                        :state="error.answerDCheckBox"
                                        placeholder="Entrez ici la réponse D"
                                    />
                                </div>
                            </section>
                        </b-form-group>
                    </b-form-checkbox-group>
                    <p class="text-muted font-weight-ligh font-italic">
                        Cochez la/les case(s) pour indiquer la/les bonne(s) réponse(s)
                    </p>
                </b-form-group>
            </div>
            <div @mouseover="$emit('resetAnimation')" v-if="itemForm.type == 'questionnaire' || itemForm.type == 'end'">
                <div v-if="showAnswers" class="w100 d-flex flex-row justify-content-between">
                    <b-button
                        size="sm"
                        variant="danger"
                        class="mr-2 "
                        :disabled="deleting"
                        @click="
                            $emit(
                                'deleteQuestion',
                                inputQuestionItem.id ? inputQuestionItem.id : statesItemForm.onFocus,
                                inputQuestionItem.id ? true : false
                            )
                        "
                        :class="this.itemForm.test.length <= 1 || this.statesItemForm.nextFormId <= 1 ? 'disabled' : ''"
                    >
                        <p v-if="!deleting" class="m-0">Supprimer</p>
                        <b-spinner v-else small></b-spinner>
                    </b-button>
                    <span>
                        <b-button size="sm" variant="primary" class="mr-2" @click="$emit('newQuestionForm')">
                            + Nouvelle question
                        </b-button>
                        <b-button size="sm" squared variant="secondary" class="border-right" @click="$emit('previousQuestionForm')">
                            &lt;&lt;
                        </b-button>
                        <b-button size="sm" squared variant="secondary">
                            {{ parseInt(statesItemForm.onFocus) + 1 + ' / ' + parseInt(statesItemForm.nextFormId) }}
                        </b-button>
                        <b-button size="sm" squared variant="secondary" class="border-left" @click="$emit('nextQuestionForm')">
                            >>
                        </b-button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        itemForm: Object,
        statesItemForm: Object,
        showAnswers: Boolean,
        inputQuestionItem: Object,
        deleting: Boolean,
        error: {
            title: Boolean,
            errorMessage: Boolean,
            question: Boolean,
            answerA: Boolean,
            answerACheckBox: Boolean,
            answerB: Boolean,
            answerBCheckBox: Boolean,
            answerC: Boolean,
            answerCCheckBox: Boolean,
            answerD: Boolean,
            answerDCheckBox: Boolean
        }
    }
};
</script>
